<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <div class="button-not-print"></div>
    <div class="main">
      <!-- แบ่งครึง panel ส่วนซ้าย -->
      <div class="left">
        <!-- ปริ้นโดย 80 MM -->
        <div v-if="printType == 1">
          <div
            v-for="(orderItem, index) in orderItemList"
            :key="index"
            style="display: flex; justify-content: center"
          >
            <v-card :elevation="0" class="pages">
              <v-row>
                <v-col cols="6">
                  <barcode
                    v-bind:value="'pa' + orderItem.id"
                    :text="'PA' + orderItem.id"
                    height="20"
                    width="1"
                    fontSize="18"
                    marginTop="1"
                    marginBottom="1"
                    textAlign="left"
                  />
                </v-col>
                <v-col cols="6">
                  <img :src="logo.logoAWB" width="100%" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                  ><span class="fon2mm pl-3"
                    >ปริ้นโดย : {{ Branch_StaffName }} , เวลา :
                    {{ DateNow() }}</span
                  ></v-col
                >
              </v-row>
              <v-card-text
                v-for="(item, index) in orderItem.orderItem"
                :key="index"
                class="pl-3"
              >
                <v-row>
                  <v-col cols="12" class="fon2mm">
                    <span v-if="item.rackNo != null"
                      >Rack : {{ item.rackNo }} ,</span
                    >
                    <span v-if="item.barcode != null">
                      Barcode : {{ item.barcode }} ,
                    </span>
                    <span v-if="item.sku != null"> sku : {{ item.sku }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="item.prop1Description || item.prop2Description">
                  <v-col cols="12" class="fon2mm">
                    <span>
                      ลักษณะ : {{ item.prop1Description }}
                      {{ item.prop2Description }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="9" class="fon2mm">
                    <span>
                      <template v-if="item.sku">[{{ item.sku }}] </template>
                      {{ item.productName }}</span
                    >
                  </v-col>

                  <v-col cols="3" class="fon2mm">
                    <span> x {{ item.quantity }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="pl-3" v-if="orderItem.remark "> 
                <v-row>
                  <v-col cols="12" class="fon2mm">
                    <span>หมายเหตุ : {{ orderItem.remark }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions v-if="!logo.isSubscription" class="d-flex" style="justify-content: center" >
                <div class="box fon1mm text-center">
                  <span> PACKHAI</span> <span>คลังสินค้าออนไลน์</span>
                  <span> ช่วยแพ็ค ช่วยขาย ครบวงจร</span>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <div v-else-if="printType == 2" >
          <div v-for="(orderItem, index) in orderItemList" :key="index" style="display: flex; justify-content: center" >
            <v-card :elevation="0" class="pageA4" >
              <table>
                <tr>
                  <th>
                    <barcode
                      v-bind:value="'PA' + orderItem.id"
                      :text="'PA' + orderItem.id"
                      height="25"
                      width="1"
                      fontSize="18"
                      marginTop="10"
                      marginBottom="5"
                    />
                  </th>
                  <th>
                    <div v-if="orderItem.referenceNumber">
                      <barcode
                        v-bind:value="orderItem.referenceNumber"
                        :text="orderItem.referenceNumber"
                        height="25"
                        width="1"
                        fontSize="18"
                        marginTop="10"
                        marginBottom="1"
                        textMargin="-2"
                      />
                    </div>

                    <!-- <div>ช่องทาง</div> -->
                    <div>ช่องทาง : {{ orderItem.channelName }}</div>
                  </th>
                  <th>
                    <div>วันที่ปริ้น : {{ DateNow() }}</div>
                    <div>ปริ้นโดย : {{ Branch_StaffName }}</div>
                  </th>
                </tr>
              </table>
              <div class="my-2">
              <span>ลูกค้า : </span><span>{{ orderItem.receiver }}</span>
              </div>
              <table>
                <tr>
                  <th>No</th>
                  <th>รูป</th>
                  <th>Barcode</th>
                  <th>ชื่อ</th>
                  <th>จำนวน</th>
                </tr>
                <tr v-for="(item, index) in orderItem.orderItem" :key="index">
                  <td style="text-align: center">{{ index + 1 }}</td>
                  <td style="text-align: center">
                    <div>
                      <img
                        class="mr-2"
                        style="width: 60px"
                        :src="item.photoLink"
                      />
                    </div>
                  </td>
                  <td>
                    <div v-if="item.barcode">
                      <barcode
                        v-bind:value="item.barcode"
                        height="25"
                        width="1"
                        fontSize="15"
                      />
                    </div>
                    <div v-if="item.rackNo">
                      <span class="px-1"> Location :{{ item.rackNo }}</span>
                    </div>
                    <span class="px-1"> SKU :{{ item.sku }}</span>
                  </td>
                  <td style="width: 300px; padding: 0px 0px 0px 10px">
                    {{ item.productName }}
                  </td>
                  <td style="text-align: center">{{ item.quantity }}</td>
                </tr>
              </table>
              <div class="my-2">หมายเหตุ : {{ orderItem.remark }}</div>
            </v-card>
          </div>
        </div>
      </div>
      <!-- แบ่งครึง panel ส่วนขวา -->
      <div class="right button-not-print">
        <div class="scroll-container">
          <v-row>
            <v-col> </v-col>
          </v-row>
          <v-row class="btn-chip-group">
            <v-col cols="4">
              <span> ขนาดกระดาษ :</span>
            </v-col>
            <v-col cols="8" class="">
              <v-radio-group v-model="printType" row>
                <v-radio
                  :label="items.name"
                  v-for="items in viewTypePrint"
                  :key="items.id"
                  :value="items.id"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="checkbox">
            <v-col cols="4">
              <span> การจัดเรียง : </span>
            </v-col>
            <v-col cols="8">
              <v-checkbox
                hide-details
                v-model="orderByType"
                label="พิมพ์ตามจำนวนสินค้าน้อยไปมาก"
                @click="BtnOrderByType(orderByType)"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="btn-print">
            <v-col cols="10">
              <v-btn
                :elevation="1"
                color="success"
                dark
                block
                outlined
                x-large
                @click="btnPrint()"
              >
                <v-icon size="25" class="pr-1">fa-solid fa-print</v-icon>
                พิมพ์
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="content-container">
          <!-- content goes here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import axios from "axios";
import Swal from "sweetalert2"; 
import Loading from "@/website/components/Loading";
import {
  shopService_dotnet,
  orderService_dotnet,
  branchService_dotnet,
} from "@/website/global";

export default {
  components: {
    barcode: VueBarcode,
    Loading,
  },

  data: () => ({
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    logo: "@/assets/C_logopackhai.png",
    packageIDList: [],
    page_loading: true,
    loading: false,
    staffBranchId:null,
    viewTypePrint: [
      { id: 1, name: "80 mm" },
      { id: 2, name: "A4" },
    ],

    printType: 1,
    orderItemList: [],
    orderItemListOld:null,

    items: [
      {
        sku: "S000003-3",
        barcode: "885000000033",
        name: "โมเดลรถ CHEVROLET",
        productCode: "C00003",
        prod1: "",
        total: "10",
      },
      {
        sku: "S000003-3",
        barcode: "885000000033",
        name: "โมเดลรถ CHEVROLET",
        productCode: "C00003",
        prod1: "",
        total: "10",
      },
    ],
    item: [],
    Branch_StaffName: "",
    orderByType: false,
   
  }),
  async created() {
    this.Branch_StaffName = localStorage.getItem("Branch_StaffName");
    this.staffBranchId = localStorage.getItem("Branch_BranchID");
    await this.getLogo(this.staffBranchId);


    this.packageIDList = decodeURIComponent(sessionStorage.getItem("packageIDList"));
    await this.loadDataSeparately(this.packageIDList);

    this.page_loading = false;
  },
  methods: {

     //ส่วนของ ฟังก์ชั่นเช็คตรวจสอบ

    DateNow() {
      var today = new Date();
      var date =
        (today.getDate().toString().length > 1
          ? today.getDate().toString()
          : "0" + today.getDate().toString()) +
        "-" +
        ((today.getMonth() + 1).toString().length > 1
          ? (today.getMonth() + 1).toString()
          : "0" + (today.getMonth() + 1).toString()) +
        "-" +
        today.getFullYear();
      var h =
        today.getHours().toString().length > 1
          ? today.getHours().toString()
          : "0" + today.getHours().toString();
      var m =
        today.getMinutes().toString().length > 1
          ? today.getMinutes().toString()
          : "0" + today.getMinutes().toString();
      var s =
        today.getSeconds().toString().length > 1
          ? today.getSeconds().toString()
          : "0" + today.getSeconds().toString();
      var time = h + ":" + m + ":" + s;
      var newDatetime = date + " " + time;
      return newDatetime;
    },
    async orderBy() {
      this.loading = true;
      var cloneOBj = [...this.orderItemList];
      
      
      // var orderNew =   this.orderItemList.sort((a,b)=> (a.skuCount > b.skuCount ? 1 : -1));

      var orderNew = cloneOBj.sort((a, b) => {
        if (a.skuCount !== b.skuCount) {
          return a.skuCount > b.skuCount ? 1 : -1;
        } else if (a.orderItem.rackNo !== b.orderItem.rackNo) {
          return a.orderItem.rackNo > b.orderItem.rackNo ? 1 : -1;
        } else {
          return a.orderItem.productName > b.orderItem.productName ? 1 : -1;
        }
      });

      this.orderItemList = orderNew;
      this.loading = false;
    },
    async loadDataSeparately(packageIDList) {
      var object = JSON.parse(packageIDList);
      let amount_of_data_each = 50; // จำนวนข้อมูลที่มีในแต่ละก้อน
      let countObject = object.length;
      let loopCountFetch = Math.ceil(countObject / amount_of_data_each); // จำนวนที่ต้องวนข้อมูล
      let bodyOut = [];
      for (let i = 0; i < loopCountFetch; i++) {
        bodyOut[i] = object.splice(0, amount_of_data_each);
        var data_obj = await this.getOrderPackingList(bodyOut[i]);

        if (data_obj != null) {
          this.orderItemList.push(...data_obj);
        }
      }
    },

    //ส่วนของ API ยิง

    async getLogo(branchId) {
      await axios
        .post(
          branchService_dotnet + "Branch/get-branch-single",
          {
            branchId: branchId,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          this.logo = res.data.branch;
          // console.log(res,"Logo")
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getOrderPackingList(packageIDList) {
      return await axios
        .post(
          orderService_dotnet + "Package/get-print-packing-list",
          {
            packageIDList: packageIDList,
            staffBranchID: this.staffBranchId,
          },
          { headers: this.header_token }
        )
        .then((res) => {
          return res.data.data;
          //  this.orderItemList = res.data.data
        })
        .catch((error) => {
          this.loading = false;
          return null;
        });
    },
   
    //ส่วนของ btn กดแล้วทำงาน
    BtnOrderByType(type){
      if(this.orderItemListOld == null){
        this.orderItemListOld =  [...this.orderItemList];
      }
      if(type){
        this.orderBy();
      }else{
        this.orderItemList = this.orderItemListOld;
      }
    },

    async btnPrint() {
      await print();
    },
  },
};
</script>

<style scoped>
>>> .vue-barcode-element {
  width: 100% !important;
}


.fon2mm {
  font-size: 3mm;
  color: #000;
  line-height: 3.5mm;
}
.fon1mm {
  font-size: 2.5mm;
  color: #000;
  line-height: 2.4mm;
}


.cen {
  display: flex;
  align-items: center;
}

.box {
  padding: 3px 10px 3px 10px;
  height: 7mm;
  border: 1px solid;
  width: 37mm;
}

@media print {
  .button-not-print {
    display: none;
  }
  .pages {
    size: 80mm landscape;
    width: 80mm;
    margin: 0;
    page-break-after: always;
  }
  .pageA4 {
    margin: 0;
    size: 21cm 29.7cm !important;
    page-break-after: always;
    width: 20cm;
    height: 28cm;
  }

  @page {
    margin-top: 1 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .fon2mm {
    font-size: 9pt;
    color: #000;
  }
  .fon1mm {
    font-size: 7pt;
    color: #000;
  }


  .main {
    /* display: grid;
  grid-template-columns: 50% 50%; */
  }

  .left {
    /* background-color: white; */
  }

  .right {
    /* background-color: white; */
  }
}

@media screen {
  .pages {
    width: 80mm;
    /* display: none; */
  }
  .pageA4 {
    size: A4;

    width: 20cm;
    height: 28cm;
  }
  .main {
    display: grid;
    grid-template-columns: 70% 30%;
    height: 100%;
  }

  .left {
    background-color: #cbcbcb;
  }

  .right {
    background-color: white;
    height: 100vh;
  }
}

table,
td,
th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}


.scroll-container {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 100vh;
  background-color: white;
}

.btn-print {
  position: absolute;
  top: 90%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox {
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: baseline;
}
.btn-chip-group {
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  font-size: 1rem;
  text-align: right;
  color: black;
  width: 100%;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.checkbox {
  position: absolute;
  top: 15%;
  transform: translateY(-50%);
  font-size: 1rem;
  text-align: right;
  vertical-align: baseline;
  color: black;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-warning {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  text-align: center;
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  flex-grow: 1;
  padding: 20px;
}
</style>